import React, { useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { graphql } from "gatsby";
import { Link } from "gatsby-plugin-react-i18next";
import Trans, { t } from "../components/Translate";
// import Header from "./components/Header";
import Layout from "../layouts/default";
import "./styles.scss";
import Cookies from "js-cookie";
import Modal from "../components/Modal";
import AboutContent from "../components/AboutContent";
const CleaningImages = () => {
  return (
    <>
      <div className="circle-image woman">
        <img
          // id="cleaning-woman"
          className="rounded-circle cleaning-image mr-2 woman-image"
          src="../pic-1.jpg"
          alt={t("CLEANING_WOMAN")}
        />
      </div>
      <div className="circle-image mop">
        <img
          src="../pic-2.jpg"
          className="rounded-circle cleaning-image mx-2 cleaning-product"
          alt={t("MOP")}
        />
      </div>
      <div className="circle-image vacuum">
        <img
          src="../pic-3.jpg"
          className="rounded-circle cleaning-image ml-2 cleaning-product"
          alt={t("VACUUM")}
        />
      </div>
    </>
  );
};
const IndexPage = props => {
  const [showAboutModel, setShowAboutModel] = useState(false);
  // const userToken = Cookies.get("userToken");
  const [logout, { data, loading, error }] = useMutation(gql`
    mutation Logout {
      logout {
        responseCode
      }
    }
  `);

  const { data: meData, loading: meQueryLoading } = useQuery(
    gql`
      query Me {
        me {
          id
          firstName
          lastName
          emailAddress
          isAdministrator
        }
      }
    `,
    { fetchPolicy: "no-cache" }
  );
  const isLoggedin = meData?.me && !meQueryLoading;

  return (
    <Layout pageName="home" metaTitle={t("HOME_META_TITLE")}>
      <main id="home-page">
        <section className="hero-image">
          <div
            id="hero-image-inner-container"
            className="container d-flex flex-column-reverse d-lg-block m-auto pb-4 pb-lg-0"
          >
            <div id="hero-left-content" className="px-0 px-lg-4">
              <div
                id="hero-text-and-buttons-wrapper"
                className="d-flex flex-column-reverse flex-lg-column w-100"
              >
                <div className="mb-4" id="hero-text-wrapper">
                  <p id="hero-text-header" className="text-white">
                    <b>
                      <Trans i18nKey={"HOUSE_CLEANING_&_MAINTENANCE"} />
                    </b>
                  </p>
                  <p className="text-white mb-0">
                    <b>
                      <Trans i18nKey={"LUXURY_WATERFRONT"} />
                    </b>
                  </p>
                  <span className="text-white">
                    <Trans i18nKey={"SPECIALIZING"} />
                  </span>
                </div>

                <div
                  id="hero-buttons"
                  className="d-block d-lg-flex mb-5 mr-3 w-100"
                >
                  <Link
                    to="/register"
                    className={`btn btn-secondary hero-button mb-3 mb-lg-0 font-weight-bold w-100 d-flex align-items-center justify-content-center ${
                      isLoggedin ? "invisible position-absolute" : "visible"
                    }`}
                  >
                    {meQueryLoading ? "" : <Trans i18nKey={"REGISTER_CAPS"} />}
                  </Link>
                  <Link
                    to="/login"
                    className={`btn btn-light hero-button ml-lg-3 font-weight-bold w-100 d-flex align-items-center justify-content-center ${
                      isLoggedin ? "invisible position-absolute" : "visible"
                    }`}
                  >
                    {meQueryLoading ? "" : <Trans i18nKey={"LOG_IN_CAPS"} />}
                  </Link>{" "}
                  <Link
                    to="/shifts"
                    className={`btn btn-secondary hero-button mb-3 mb-lg-0 font-weight-bold w-100 d-flex align-items-center justify-content-center  ${
                      isLoggedin ? "visible" : "invisible position-absolute"
                    }`}
                  >
                    {meQueryLoading ? (
                      ""
                    ) : (
                      <Trans i18nKey={"UPCOMING_SHIFTS"} />
                    )}
                  </Link>
                  <a
                    onClick={async () => {
                      try {
                        const logoutResult = await logout();
                        if (
                          logoutResult?.data?.logout?.responseCode ===
                          "LOGOUT_SUCCESS"
                        ) {
                          Cookies.remove("userToken");
                          location.reload();
                          //   navigate("/");
                        }
                      } catch (err) {}
                    }}
                    className={`btn btn-light hero-button ml-lg-3 font-weight-bold w-100 d-flex align-items-center justify-content-center  ${
                      isLoggedin ? "visible" : "invisible position-absolute"
                    }`}
                  >
                    {meQueryLoading ? "" : <Trans i18nKey={"LOG_OUT_CAPS"} />}
                  </a>
                </div>

                <div className="d-none d-lg-flex" id="hero-cleaning-images">
                  <CleaningImages />
                </div>
              </div>
            </div>
            <div className="text-center" id="cleaning-man-container">
              <div
                id="mobile-cleaning-images"
                className="d-flex d-lg-none position-absolute h-100 w-100"
              >
                <CleaningImages />
              </div>
              <img
                src="../cleaning-man.png"
                id="cleaning-man-img"
                alt={t("CLEANING_MAN")}
              />
            </div>
          </div>
        </section>
        <section
          className="text-center d-flex align-items-center justify-content-center py-1"
          id="learn-more-wrapper"
        >
          <div className="container">
            <span className="text-light font-weight-bold">
              <Modal
                isOpen={showAboutModel}
                title=""
                disableFocusTrap={true}
                onClose={() => setShowAboutModel(false)}
                displayType="overflow"
              >
                <AboutContent />
              </Modal>
              <Link
                href="#"
                onClick={event => {
                  event.preventDefault();
                  setShowAboutModel(true);
                }}
                className="text-light"
              >
                <u>
                  <Trans i18nKey={"LEARN_MORE"} />
                </u>
              </Link>{" "}
              <Trans i18nKey={"ABOUT_JOB_POSITIONS"} />
            </span>
          </div>
        </section>
        <section className="py-5" id="how-it-works-wrapper">
          <div className="container">
            <div className="mb-5 position-relative underline-header-wrapper">
              <h1 className="mb-2">
                <Trans i18nKey={"HOW_IT_WORKS"} />
              </h1>
              <div className="custom-border-bottom"></div>
            </div>
            <div className="d-block d-lg-flex justify-content-between">
              <div>
                <h2 className="mb-3">
                  <Trans i18nKey={"SIGN_UP"} />
                </h2>
                <p>
                  <Link to="/register">
                    <Trans i18nKey={"REGISTER"} />
                  </Link>{" "}
                  <Trans i18nKey={"NO_EXPERIENCE"} />
                </p>
              </div>
              <div className="mx-0 mx-lg-5">
                <h2 className="mb-3">
                  <Trans i18nKey={"BOOK_UPCOMING_SHIFTS"} />
                </h2>
                <p>
                  <Link to="/shifts">
                    <Trans i18nKey={"CHOOSE_YOUR_OWN_SHIFTS"} />
                  </Link>{" "}
                  <Trans i18nKey={"SET_HOURS"} />
                </p>
              </div>
              <div>
                <h2 className="mb-3">
                  <Trans i18nKey={"GET_PAID"} />
                </h2>
                <p>
                  {" "}
                  <Trans i18nKey={"EARN_MONEY"} />
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="py-5" id="what-sets-us-apart-wrapper">
          <div className="container">
            <div className="mb-5 position-relative underline-header-wrapper">
              <h1 className="mb-2">
                <Trans i18nKey={"WHAT_SETS_US_APART"} />
              </h1>
              <div className="custom-border-bottom"></div>
            </div>
            <div className="d-block d-lg-flex">
              <div>
                <img className="mb-4" src={"../icon-flexible.svg"} />
                <h2 className="mb-3">
                  <Trans i18nKey={"FLEXIBLE"} />
                </h2>
                <p>
                  <Trans i18nKey={"WORK_SHIFTS_AVAILABLE"} />
                </p>
              </div>
              <div className="mx-0 mx-lg-5">
                <img className="mb-4" src={"../icon-convenient.svg"} />
                <h2 className="mb-3">
                  <Trans i18nKey={"CONVENIENT"} />
                </h2>
                <p>
                  {" "}
                  <Trans i18nKey={"CONTRACTOR_POSITION"} />
                </p>
              </div>
              <div>
                <img className="mb-4" src={"../icon-training.svg"} />
                <h2 className="mb-3">
                  <Trans i18nKey={"ON_THE_JOB_TRAINING"} />
                </h2>

                <p>
                  <Trans i18nKey={"AFTER_REGISTRATION"} />
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="py-5" id="about-the-work-wrapper">
          <div className="container">
            <div className="mb-5 position-relative underline-header-wrapper">
              <h1 className="mb-2">
                <Trans i18nKey={"ABOUT_THE_WORK_CAPS"} />
              </h1>
              <div className="custom-border-bottom"></div>
            </div>
            <div className="d-block d-lg-flex">
              <div className="mr-0 mr-lg-5 text-center">
                <img
                  id="cleaning-supplies-image"
                  className="mb-4 rounded-circle"
                  src={"../pic-about.jpg"}
                />
              </div>
              <div>
                <p>
                  <Trans i18nKey={"WE_PROVIDE"} />
                </p>
                <p>
                  <Trans i18nKey={"EACH_TEAM"} />
                </p>
                <p>
                  <Trans i18nKey={"LEARN_MORE"} />{" "}
                  <Link
                    href="#"
                    onClick={event => {
                      event.preventDefault();
                      setShowAboutModel(true);
                    }}
                  >
                    <Trans i18nKey={"ABOUT_THE_WORK"} />
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default IndexPage;
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
