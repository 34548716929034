import React from "react";
import Trans from "../Translate";

export default () => {
  return (
    <>
      <section className="mb-5">
        <div className="d-block d-lg-flex align-items-end mb-4">
          <h1 className="mb-0 mr-2 content-header">
            <Trans i18nKey={"ABOUT_THE_WORK_CAPS"} />:
          </h1>
          <span>
            "<Trans i18nKey={"6_HOUR_CLEAN"} />"
          </span>
        </div>
        <p className="">
          <Trans i18nKey={"OUR_COMPANY_PROVIDES"} />
        </p>
        <p className="">
          <Trans i18nKey={"INCLUDED_AMOUNG_SERVICES"} />
        </p>
        <p>
          <Trans i18nKey={"EACH_TEAM"} />
        </p>
      </section>
      <section className="mb-5">
        <h1 className="mb-3 content-header">
          <Trans i18nKey={"SHIFTS_&_SHIFT_POSITIONS"} />:
        </h1>
        <p>
          <Trans i18nKey={"WORK_SHIFTS_AVAILABLE_EXTENDED"} />
        </p>
      </section>
      <section className="mb-5">
        <p>
          <Trans i18nKey={"POSITIONS"} />:
        </p>
        <p className="">
          <i>
            <Trans i18nKey={"CREW_LEADER_SUPERVISOR"} />:
          </i>{" "}
          <Trans i18nKey={"CREW_LEADER_EXPERIENCE"} />
        </p>
        <p className="">
          <i>
            <Trans i18nKey={"OUTSIDE_MAINTENANCE_REPAIRS"} />:
          </i>{" "}
          <Trans i18nKey={"PERSON_IS_RESPONSIBLE"} />
        </p>
        <p className="mb-0">
          <i>
            <Trans i18nKey={"INSIDE"} />:
          </i>{" "}
          <Trans i18nKey={"CLEANING_PERSONS"} />
        </p>
      </section>
      <section className="mb-5">
        <h1 className="mb-3 content-header">
          <Trans i18nKey={"STATUS_&_COMPENSATION"} />:
        </h1>
        <p className="">
          <Trans i18nKey={"WORKER_INDEPENDENT"} />
        </p>
        <p className="">
          <Trans i18nKey={"STARTING_COMPENSATION"} />
        </p>
        <p>
          <Trans i18nKey={"PAYMENTS"} />
        </p>
      </section>
      <section className="mb-5">
        <h1 className="mb-3 content-header">
          <Trans i18nKey={"4PM_INSPECTION"} />:
        </h1>
        <p>
          <Trans i18nKey={"RELATED_WORK"} />
        </p>
      </section>
      <section>
        <h1 className="mb-3 content-header">
          <Trans i18nKey={"TRAINING"} />:
        </h1>
        <p className="mb-0">
          <Trans i18nKey={"AFTER_REGISTRATION_EXTENDED"} />
        </p>
      </section>
    </>
  );
};
